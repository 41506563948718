@import '../_variables';

#payment-action-bar {
  padding: 0;
  margin-left: 0px;
}

#payment-action-bar .p-card {
  padding: 0;
  margin-left: 0px;
  padding: 0px !important;
}

#payment-action-bar>.p-card-body {
  padding: 0;
}

#payment-action-bar>.p-card-body .p-card-content {
  padding: 0;
}

#tierName {
  @include fontStyle("Roboto", 12px, #008DD5);
  font-weight: bold !important;
}

#paymentId {
  @include fontStyle("Roboto", 12px, #000);
}

#paymentId:hover {
  @include fontStyle("Roboto", 12px, #008DD5);
}

#transactionId {
  @include fontStyle("Roboto", 12px, #000);
}

#scheduleId {
  @include fontStyle("Roboto", 12px, #000);
}

#amount {
  @include fontStyle("Roboto", 12px, #000);
}

#fees {
  @include fontStyle("Roboto", 12px, #000);
}

#redeemable {
  @include fontStyle("Roboto", 12px, #000);
}

#occured {
  @include fontStyle("Roboto", 12px, #495057);
}

#tierSub {
  @include fontStyle("Roboto", 12px, #495057);
}

.groupName {
  @include fontStyle("Roboto", 11px !important, #8f9395 !important);
  margin-top: 0.5rem
}

.countNumber {
  @include fontStyle("Roboto", 14px, #000);
}

.countText {
  @include fontStyle("Roboto", 14px, #494949);
  font-weight: bold;
}

.payment-count {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.report_calender>span>.p-inputtext:disabled {
  background-color: #666678;
  color: whitesmoke !important;
}

.report_calender>span>.p-button:disabled {
  background-color: #666678;
  border: none;
  color: white;
}

.report_calender>span>.p-inputtext:disabled::placeholder {
  color: white;
}

.p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  height: auto;
}

#data_table .p-datatable-tbody>tr>.payment-id {
  width: 135px;
}

#data_table .p-datatable-tbody>tr>.subscription-info {
  width: 300px;
}

#data_table .p-datatable-tbody>tr>.collected-info {
  width: 200px;
}

#data_table .p-datatable-tbody>tr>.tier-info {
  width: 300px;
  text-align: left !important;
}

#data_table .p-datatable-tbody>tr>.note {
  width: 300px;
  text-align: left !important;
}

#data_table .p-datatable-tbody>tr>.occurred-on {
  width: 128px;
}

#data_table .p-datatable-tbody>tr>.schedule-for {
  width: 130px;
}

#data_table .p-datatable-tbody>tr>.schedule-status-payments {
  width: 180px;
}

#data_table .p-datatable-tbody>tr>.subscription-status {
  width: 180px;
}

#data_table .p-datatable-tbody>tr>.collected {
  width: 138px;
}

#data_table .p-datatable-tbody>tr>.fees {
  width: 125px;
}

#data_table .p-datatable-tbody>tr>.revenue {
  width: 135px;
}

#data_table .p-datatable-tbody>th>.no-revenue {
  width: 0px !important;
}

#data_table .p-datatable-tbody>tr>.no-revenue {
  width: 0px;
}

#data_table .datatable-cell-default .subscription-info>.p-datatable .p-column-header-content {
  display: flex !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

#data_table .datatable-cell-default .tier-info>.p-datatable .p-column-header-content {
  display: flex !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

#data_table>div>table>thead>tr>th>.p-column-header-content>span {
  @include fontStyle("Roboto" !important, 14px !important, #666678 !important);
  font-weight: bold !important;
}

#data_table>div>table>thead>tr>.subscription-info>.p-column-header-content {
  display: flex !important;
  text-align: left !important;
  justify-content: flex-start !important;
}
#data_table>div>table>thead>tr>.collected-info>.p-column-header-content {
  display: flex !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

#data_table>div>table>thead>tr>.tier-info>.p-column-header-content {
  display: flex !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

#data_table>div>table>thead>tr>.note>.p-column-header-content {
  display: flex !important;
  text-align: left !important;
  justify-content: flex-start !important;
}

#data_table>div>table>thead>tr>.no-revenue>.p-column-header-content {
  width: 0px !important;
}

.autocomplete-container {
  position: relative;
}

.clear-icon {
  position: absolute;
  right: 10px;
  /* Adjust the right value to control the position within the input field */
  top: 50%;
  /* Vertically center the icon */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  // display: none; /* Initially hide the icon */
}

.active_dropdown,
#active_dropdown,
#period_active_dropdown {
  border: 1.2px solid #008DD5;
  box-shadow: 0 0 5px rgba(0, 141, 213, 1);
  display: inline-flex;
  align-items: center;
  padding: 8
}

#period_active_dropdown {
  margin-right: 1rem;
}
@import "../_variables";

.search.p-card {
    width: 100%;
    margin-left: 0px;
    margin-top: 1rem;

    .p-button {
        height: 100%;
        margin: 0.5rem 0px;
        margin: auto;
        margin: auto 0px;
        border: 1px solid #e0e0e0;
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        &.p-button-danger {
            border-right: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }

    .p-inputtext {
        background-color: #fff;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;
    }
}

body .customerContent .p-tabview .p-tabview-panels {
    background-color: transparent;
    padding: 0px;
}

.customerAction .p-button-icon {
    color: #d0021b;
    font-size: 18px;
}

.customer_table_action_button {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    height: auto;
}

.search .p-inputtext:enabled:focus {
    box-shadow: 0 0 0.3rem transparentize($rev_blue, 0.5);
    border-color: $rev_blue;
}

.business-customer-phoneNo {
    @include fontStyle("Roboto-Bold", 18px, #008dd5);
}

.business-customer-name {
    @include fontStyle("Roboto-Medium", 16px, #495057);
}

.business-customer-email {
    @include fontStyle("Roboto", 16px, #495057);
}

.business-customer-id {
    @include fontStyle("RobotoCondensed", 16px, #495057);
}

.business-customer-id-value {
    margin-left: 2px;
    @include fontStyle("Roboto-Bold", 16px, #000000);
}

.business-customer-subs {
    @include fontStyle("Roboto-Bold", 18px, #000000);
}

.business-customer-subs-text {
    @include fontStyle("RobotoCondensed-Bold", 12px, #666678);
    font-weight: bold;
}

.business-customer-amnt {
    @include fontStyle("RobotoCondensed", 16px, #66bb6a);
    font-weight: bold;
}

.business-customer-amnt-text {
    @include fontStyle("RobotoCondensed-Bold", 12px, #66bb6a);
    font-weight: bold;
}

.business-customer-missed-amnt {
    @include fontStyle("RobotoCondensed", 16px, #fe3031);
    font-weight: bold;
}

.business-customer-upcoming-amnt {
    @include fontStyle("RobotoCondensed", 16px, #9047f6);
    font-weight: bold;
}

.business-customer-missed-amnt-text {
    @include fontStyle("RobotoCondensed-Bold", 12px, #fe3031);
    font-weight: bold;
}

.action-button {
    color: #d2132b !important;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;

    .pi {
        font-weight: bold;
    }
}

.subs-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: auto;
    margin: 0.2rem;
}

.inactive-action-button {
    color: #d2132b !important;
    background-color: white !important;
    border: none !important;
    cursor: pointer !important;
}

.title-content {
    @include fontStyle("Roboto-Bold", 12px, #008dd5);
    margin-top: 5px;
}

.card-info {
    margin: 5px 0px;
    @include fontStyle("RobotoCondensed", 16px, #495057);
}

.business-customer-activities {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.activities-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tier-name-content {
    @include fontStyle("Roboto-Bold", 14px, #000000);
}

.tier-name-description {
    @include fontStyle("Roboto", 12px, #495057);
}

.tier-duration {
    @include fontStyle("Roboto", 14px, #666678);
}

.card-spacing {
    margin-top: 0px !important;
}

.card-content-spacing {
    padding: 0.15rem !important;
}

.activities-content {
    width: 33%;
}

body .p-inputtext:enabled:hover {
    border: 1px solid #bdbdbd;
}

.buttons {
    margin-left: -4rem !important;
}

.subscriber-detail-screen .wallet-titles {
    margin-left: 0 !important;
}

.customerCard {
    border-radius: 20px !important;
    margin-left: 0 !important;
    width: 100% !important;

    &-labels {
        @include fontStyle("RobotoCondensed-Bold", 16px, $rev_gray);
    }

    &-subs-text {
        @include fontStyle("RobotoCondensed-Bold", 12px, #000);
    }

    &-subs-num {
        @include fontStyle("RobotoCondensed-Bold", 16px, $rev_blue);
    }

    &-value {
        @include fontStyle("Roboto", 16px, #000);
    }

    &-count {
        @include fontStyle("Roboto-Bold", 20px, #000);
    }

    &-amount {
        @include fontStyle("Roboto-Bold", 12px, #000);

        &.collected {
            @include fontStyle("Roboto-Bold", 16px, $rev_green);
        }

        &.revenue {
            @include fontStyle("Roboto-Bold", 16px, $rev_blue);
        }

        &.missed {
            @include fontStyle("Roboto-Bold", 16px, $rev_sec);
        }
    }
}

.sub-label {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    font-family: 'RobotoCondensed';
}

.side-title{
    font-family: "Roboto-Bold";
    color: #008DD5;
    font-size: 12px;
}
.ref-label {
    font-family: "Roboto";
    color: #008DD5;
}

.name-label {
    color: #000;
    font-size: 17.5px;
    font-weight: 400;
}

.contact-label-phoneNo {
    font-family: Roboto-Bold;
    font-size: 14px;
    font-weight: 400;
    color: #008DD5;
}

.contact-label-email {
    color: #666678;
    font-family: Roboto-Bold;
    font-size: 14px;
    font-weight: 400;
}

.customerNum-label {
    color: #000;
    font-family: "Roboto";
    font-weight: 400;
}

.count-curren {
    font-family: Roboto;
    font-size: 12px;
    padding: .4rem .2rem 0 0;
}

.small-card-title {
    color: #000;
    font-family: RobotoCondensed-bold;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 20px;
}

.customer_detail_table {
    width: auto;
    margin-left: 0rem;
}

.scheduleed {
    width: 8rem;

    &-title {
        color: #666678;
        font-family: Roboto-Bold;
        font-weight: 400;
        font-size: 12px;
        line-height: 0rem;
    }

    &-status-box {
        width: 6rem;
        display: flex;
        justify-content: center;
    }

    &-date,
    &-time {
        color: #666678;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;
    }
}

.upcoming-amount {
    color: #000;
    font-family: Roboto-bold;
    font-size: 14px;
    font-weight: 400;
}

.cust {
    &-num {
        font-family: Roboto-bold;
        font-weight: 400;
        color: #000;
        font-size: 14px;
        padding-bottom: 8px;
    }

    &-name {
        color: #495057 !important;
        font-size: 12px;
        padding-bottom: 3px;
    }

    &-phoneNo,
    &-email {
        color: #495057;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
    }
}
@media (max-width: 1024px) {
    .subscriber-detail-screen .products-report-content {
        height: 20rem !important;
    }
}
.summaryCards {
    .card {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        width: 250px;
        height: auto;

        &-info {
            display: flex;
            flex-direction: column;

            &-collected {
                justify-content: space-between;
                @include fontStyle("Roboto-Bold", 14px, $rev_green);
            }

            &-missed {
                @include fontStyle("Roboto-Bold", 14px, $rev_pri);
            }

            &-payment {
                @include fontStyle("Roboto-Bold", 14px, #FFB300);

            }

            &-amount {
                @include fontStyle("Roboto-Bold", 14px, #000);
            }

            &-name {
                @include fontStyle("Roboto", 12px, $rev_gray);
            }
        }

        .title-content {
            color: #000;
            ;
        }
    }
}

.tier-customer-amnt-text {
    @include fontStyle("RobotoCondensed-Bold", 12px, #008dd5);
}

.email-error-text {
    color: red;
    font-size: 12px;
    font-weight: 200;
}

.plan-text {
    color: #666678 !important;
    font-size: 12px;
    font-weight: 500;
}

.reference-text-customers {
    color: #008dd5 !important;
    margin-bottom: 16px;
}

.subsTemplate-header {
    & div {
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        color: #666678 !important;
    }

    & span {
        font-size: 12px !important;
        color: #666678;
        font-family: "Roboto-Bold";
    }
}

.amount-header {
    & div {
        color: #666678 !important;
    }

    & span {
        font-size: 12px !important;
        color: #666678;
        font-family: "Roboto-Bold";
    }
}

.status-body {
    width: 10px !important;
}

.status_body{
    border-radius: 15px !important;
    width: 7.2rem !important;
}
.date-style{
    width: 8rem;
}

.status-date {
    @include fontStyle("Roboto-Bold", 11px, #666678);
    margin-top: 12px;
}

.status-body {
    width: 10px;
    background-color: red;
}

.customerNum-error-text {
    color: red;
    font-size: 12px;
    font-weight: 200;
}

.customer_name {
    @include fontStyle("Roboto-Bold", 12px, #495057);
    display: block;
    margin-bottom: 3px;
}

.customerReference {
    @include fontStyle("Roboto-Bold", 14px, #000);
    margin-bottom: 3.5px;
}

.customer_data {
    @include fontStyle("Roboto", 12px, #495057);
    display: block;
    margin-bottom: 3px;
}

.only_customer_name {
    @include fontStyle("Roboto", 14px, #000);
}

.customer_reference {
    @include fontStyle("Roboto-Bold", 12px, #000);
    margin-top: 5px;
}

.subscriptionReference {
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
    margin-bottom: 5.5px;
    cursor: pointer;
}

.plan_name {
    @include fontStyle("Roboto-Bold", 12px, #495057);
    margin-bottom: 5.5px;
    display: flex;
    text-align: left;
}

.plan-amount {
    @include fontStyle("Roboto", 12px, #495057);
    margin-bottom: 2.5px;
}

.plan-billingPeriod {
    @include fontStyle("Roboto-Bold", 12px, #D0021B);
    margin-bottom: 4.5px;
}

.more-subs {
    text-decoration: underline;
    margin-left: 1rem;
    @include fontStyle("Roboto", 11px, #008DD5);
    cursor: pointer;
    margin-bottom: 0px;
}

.latest {
    @include fontStyle("RobotoCondensed", 12px, #9747FF);
    text-decoration: underline;
    margin-left: 0.5rem;
    margin-bottom: 0px;
    font-weight: bold;
    cursor: pointer;
}

.latest-info {
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: center;
    align-items: flex-start;
}

.latest-name {
    @include fontStyle("Roboto-Bold", 14px, #495057);
    margin-bottom: 2px;
}

.latest-date {
    margin-bottom: 2px;
    @include fontStyle("Roboto-Bold", 14px, #66BB6A);
}

.latest-amount {
    @include fontStyle("Roboto-Bold", 14px, #000);
}

.arrow-subs {
    cursor: pointer;
    margin-left: 0.7px;
    color: #008DD5;
    height: 1rem;
}

.arrow-latest {
    cursor: pointer;
    margin-left: 0.7px;
    margin-bottom: 0px;
    color: #9747FF;
}

.subscription-status-column {
    display: flex;
    align-items: center;
}

.subscription-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.p-datatable .p-datatable-thead>tr>th.p-align-center.check-box-header>.p-column-header-content {
    justify-content: center;
}


#data_table .p-datatable-tbody>tr>td {
    vertical-align: top;
    padding-top: 11px;
}

#data_table .datatable-cell-default .p-checkbox-box {
    margin-left: 0px;
    margin-top: 0.82rem;
}

.p-datatable .p-datatable-thead>tr>th.p-align-center.customer_id_header>.p-column-header-content {
    justify-content: right;
}

.p-datatable .p-datatable-thead>tr>th.p-align-center.customer_info_header>.p-column-header-content {
    justify-content: left;
    align-content: left;
}

.p-datatable .p-datatable-thead>tr>th.p-align-center.subscription_Info>.p-column-header-content {
    justify-content: center;
    width: 300px;
}

.p-datatable .p-datatable-thead>tr>th.p-align-center.payments>.p-column-header-content {
    justify-content: center;
    width: 250px;
}

.activity-select {
    margin-top: 1.1rem
}

.p-checkbox-input {
    width: 2rem;
    height: 3rem;
}

.total-collected-count {
    @include fontStyle("Roboto-Bold", 14px, #000);
}

.total-collected {
    @include fontStyle("Roboto-Bold", 12px, #66BB6A);
}

.collected-label {
    @include fontStyle("RobotoCondensed", 10px, #66BB6A);
    font-weight: bold;
    margin-left: 3px;
}

.revenue {
    @include fontStyle("Roboto-Bold", 10px, #008DD5);
    margin: 0px;
}

.revenue-label {
    @include fontStyle("RobotoCondensed", 10px, #008DD5);
    font-weight: bold;
    margin-left: 3px;
}

.payment-column-main {
    max-height: -moz-max-content;
    max-height: max-content;
    display: grid;
    grid-template-columns: 150px 150px;
    gap: 6px;
}

.payment-column-1,
.payment-column-2 {
    text-align: left;
}

.missed-payment {
    @include fontStyle("Roboto-Bold", 12px, #FE3031);
    margin: 0px;
}

.missed-payment-label {
    @include fontStyle("RobotoCondensed", 10px, #FE3031);
    font-weight: bold;
    margin-left: 3px;
}

#customers_search_input {
    width: 300px;
    height: 3rem;
    border: 1px solid #bdbdbd;
    border-radius: 3px;
    margin-left: 1rem;
}

.customer_main {
    max-height: -moz-max-content;
    max-height: max-content;
    display: grid;
    text-align: right;
}

.customer_info {
    max-height: -moz-max-content;
    max-height: max-content;
    margin-top: 0rem;
    display: grid;
    grid-template-columns: 170px;
    text-align: left;
}

.next-billing {
    color: #495057;
    font-family: "Roboto-Bold";
    font-size: 12px;
    display: block;
}

.scheduled-date {
    color: #FFB300;
    font-family: "Roboto-Bold";
    font-size: 14px;
    margin-bottom: 2.5px;
    margin-top: 4.5px;
    display: block;
}

.scheduled-cost {
    color: #000000;
    font-family: "Roboto-Bold";
    font-size: 14px;
    margin-bottom: 2.5px;
    display: block;
}

.scheduled-plan-name {
    color: #495057;
    font-family: "Roboto";
    font-size: 12px;
    margin-bottom: 3.5px;
}

.customerId {
    font-size: 14px;
    font-family: "Roboto-Bold";
    color: #008DD5;
    margin-bottom: 3.5px;
    margin-top: 0rem;
}

.customerId:hover {
    color: #008dd5;
    cursor: pointer;
}

.createdOn {
    @include fontStyle("Roboto", 12px, #495057);
    display: block;
    margin-bottom: 3px;
}

.createdOnLabel {
    @include fontStyle("Roboto-Bold", 12px, #666678);
    display: block;
    margin-bottom: 3px;
}

.customer_labels {
    font-family: "Roboto";
    font-size: 11px;
    color: #000000;
    font-weight: normal;
}

.edit_forms_div {
    margin: 1.2rem 1.2rem 0rem 1.2rem;
}

.label_inputs {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
}

#first_name-required {
    height: 3rem;
    width: 300px;
    margin-top: 0.5rem;
}

#last_name-required {
    height: 3rem;
    width: 300px;
    margin-top: 0.5rem;
}

#email-required {
    height: 3rem;
    width: 300px;
    margin-top: 0.5rem;
}

#customer_id-required {
    height: 3rem;
    width: 300px;
    margin-top: 0.5rem;
}

.flexDirection_styles {
    display: flex;
    flex-direction: column;
}

.customer_headers {
    @include fontStyle("Roboto-Bold" !important, 12px !important, #666678 !important);
    font-weight: 700 !important;
}

.customerTabs {
    margin: 1rem 0rem 1rem 0rem;
}

.status-content{
   padding: 4px 10px !important; 
}

.status-content-stat {
    height: 20px;
    text-align: center;
    align-items: center;
    border-radius: 30px !important;
    font-size: 12px;
    width: 100px;
    margin-bottom: 5px;
}

.item-name {
    @include fontStyle("Roboto-Bold", 12px, #495057);
}

.subsOverlay {
    margin-top: 0px !important;
}

.p-button.p-button-info.p-button-outlined,
.p-button.p-button-info.p-button-text {
    font-family: "Roboto";
}

.search.p-card .p-button.p-button-danger {
    font-family: 'Roboto';
    background-color: #E91E63;
    color: #fff;
    border: 1px solid #E91E63 !important;
    height: 2.92rem;
}

.search-but {
    margin-top: -0.15rem !important
}

#pause_yes_btn,
#cancelSubs_yes_btn{
    @include fontStyle("Roboto-Bold" !important, 12px, #fff);
    background-color: #D0021B;
    border: none;
    border-radius: 4px;
    height: 3rem;
    width: 6rem;
}

#pause_no_btn,
#reschedule_no_btn,
#resume_no_btn,
#cancelSubs_no_btn {
    @include fontStyle("Roboto-Bold" !important, 12px, #008DD5);
    background-color: #fff;
    border: none;
    border-radius: 4px;
    height: 3rem;
    width: auto;
}

#reschedule_confirm_btn, #resumeSubs_yes_btn {
    @include fontStyle("Roboto-Bold" !important, 12px, #fff);
    background-color: #D0021B;
    border: none;
    border-radius: 4px;
    height: 3rem;
    width: 10rem;
}

.count-style {
    @include fontStyle("Roboto-Bold" !important, 15px !important, #000000);
}

.row-selection-disabled {
    pointer-events: none; // Disable pointer events for the row
}

.row-selection-disabled .p-checkbox {
    pointer-events: auto; // Enable pointer events for the selection button
}

.row-selection-disabled .subscriptionReference,
.row-selection-disabled .latest,
.row-selection-disabled .customerId,
.row-selection-disabled .arrow-subs,
.row-selection-disabled .more-subs,
.row-selection-disabled .customerAction,
.row-selection-disabled .arrow-latest {
    pointer-events: auto; // Enable pointer events for links within the row
}

.inline-span {
    display: inline-block;
    margin-right: 3px;
    font-weight: bold;
}

.block-span {
    display: block;
    margin-top: 5px;
    font-weight: bold;
}

.missed_count_number {
    @include fontStyle("RobotoCondensed-bold", 12px, #fe3031);
}

.missed_count {
    @include fontStyle("RobotoCondensed", 12px, #fe3031);
}

.setup_pt {
    padding-top: 3px;
}

.subscriptionBodyTemplate_customers {
    align-items: flex-start;
}

.flexible_amount_customers {
    display: block;
    margin-bottom: 0.5rem;
}

.actionBodyTemplate_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.successCount_span {
    @include fontStyle("Roboto-bold", 14px, #000000);
    margin-right: 5px;
}

.num_payments,
.cus_payments,
.cus_revenue,
.cus_missed {
    @include fontStyle("Roboto", 12px, #000000);
    font-weight: 100;
}

.cus_next_payment {
    @include fontStyle("Roboto-bold", 13px, #666678);
}

.cus_time {
    @include fontStyle("Roboto", 13px, #666678);
}

.customer-import-id {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0;
}
.icon-style{
    font-size: 2.5rem !important;
}
.import-black p,
.import-black-i i {
    color: #000 !important;
}

.import-green p,
.green-count,
.import-green-i i {
    color: #4caf50 !important;
}

.import-yellow p,
.import-yellow-i i {
    color: #FFB300 !important;
}

.import-red p,
.import-red-i {
    color: #D0021B;
}

.import-text {
    font-family: "RobotoCondensed";
}

.hov:hover {
    color: #000000;
}

.cus_pauseSubscriptionActions,
.cus_cancelSubscriptionActions,
.cus_resumeSubscriptionActions {
    display: flex;
    justify-content: flex-end;
}

.main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.import_button {
    font-weight: bold !important;
    font-family: 'Roboto' !important;
    background-color: #008DD5 !important;
    color: #fff;
    border: none !important;
    height: fit-content;
}

.import_cancel_button,
.import_done {
    font-weight: bold !important;
    font-family: 'Roboto' !important;
    background-color: #D0021B !important;
    color: #fff;
    border: none !important;
    height: fit-content;
}

.import_back_button {
    font-weight: bold !important;
    font-family: 'Roboto' !important;
    background-color: #fff !important;
    color: #D0021B !important;
    border: 1px solid #D0021B !important;
    height: fit-content;
}

.buttons-cont {
    display: flex;
    justify-content: space-between;
}

.cont-btn {
    display: flex;
    justify-content: end;
    margin: 3rem 2rem 2rem 0rem;
    gap: 10px;
}

#upload-button {
    display: flex;
    justify-content: space-between;
    margin: 3rem 2rem 2rem 1.5rem;
    gap: 10px;
}

.remove-file {
    font-size: 1.5rem !important;
    margin-top: 3.3rem;
    cursor: pointer;
}

.remove-file:hover {
    color: #D0021B;
}

.main-upload-card {
    margin-left: 0 !important;
    width: auto !important;
}

.title-text {
    @include fontStyle("RobotoCondensed-bold", 20px, #000);
}

.body-text {
    @include fontStyle("RobotoCondensed-bold", 16px, #000);
}

.hint-text {
    @include fontStyle("Roboto-Bold", 14px, #008DD5);
    margin: 2rem 0 0 0;
}

.upload-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(102, 102, 102, 0.15);
    border-radius: 30px;
    width: 40rem;
    height: 22rem;
    margin: 2rem 0 2rem 0rem;
}

.icon-div {
    padding: 1rem;
    border-radius: 50%;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.hint-style {
    color: #9747ff;
    font-size: 12px;
}

.download-icon {
    font-size: 2rem !important;
}

.tip-div {
    width: 40rem;
}

.apply-tip-div {
    height: 13.5rem;
}

.pop-over-div {
    display: flex;
    align-items: center;
}

.sub_text {
    width: 58rem;
    color: #000;
}

.download-csv {
    cursor: pointer;
    color: #000;
}

.download-csv:hover {
    color: #008DD5;
    font-weight: bold;
}

.custom-overlay-panel {
    width: 41rem;
    position: absolute !important;
    left: 50% !important;
    top: 450px !important;
    z-index: 1000 !important;
}

.import-preview-accordion .p-accordion .success {
    background-color: rgba(104, 159, 56, 0.25);
}

.import-preview-accordion .p-accordion .p-accordion-content, .p-datatable-responsive-table{
    padding: 0 !important;
}

.import-preview-accordion {
    .success {
        .p-accordion-toggle-icon {
            visibility: hidden !important;
        }
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: rgba(0, 141, 213, 0.1) !important;
        border-color: rgba(0, 141, 213, 0.1) !important;
        color: #000 !important;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        background: rgba(0, 141, 213, 0.1) !important;
        border: none !important;
        height: 4rem;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        color: #000 !important;
    }

    .p-accordion-header-text:hover {
        color: #000 !important;
    }

    .p-datatable-responsive-table .p-datatable-tbody>tr>td {
        border: 0;
        padding-bottom: 1.3rem;
        border-bottom: 1px solid;
        border-bottom: 1px solid #e0e0e0;
        color: #000;
    }
}

.success-import {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-family: "RobotoCondensed-Bold";
    font-size: 11px;
    color: #000;
    font-weight: normal;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
    background-color: rgba(102, 187, 106, 0.25);
    border-left: 3px solid #256029;
    font-weight: 600;

    &-text {
        @include fontStyle("RobotoCondensed-Bold", 16px, #256029);
        line-height: 0;
    }

    &-title {
        @include fontStyle("RobotoCondensed-Bold", 20px, #256029);
        line-height: 0;
        margin-block: 2rem;
    }

    &-text2 {
        @include fontStyle("RobotoCondensed-Bold", 16px, #805B36);
    }

    &-button {
        font-family: 'Roboto' !important;
        font-weight: bold !important;
        background-color: #008dd5 !important;
        border: none !important;
        margin: 0 0 1rem 0 !important;
    }
}

.card-div {
    height: 5.5rem;
}

.import-csv-upload {
    .p-button.p-fileupload-choose {
        width: auto !important;
        border-color: #008DD5;
        background-color: #008DD5;
    }
}
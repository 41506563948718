@import "../_variables";

.invite-calender>button {
    background-color: #d0021b !important;
    border: #d0021b !important;
}

.invite-calender>button>.p-icon {
    width: 2.6rem !important;
}

.title {
    @include fontStyle("Roboto", 11px, #000000 !important);
}

.p-treeselect .p-treeselect-label {
    background-color: transparent !important;
}

.p-treeselect {
    border-radius: 3px !important;
    background-color: white !important;
}


.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    color: #d0021b !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
    background-color: #d9d9d9;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    background-color: #d9d9d9 !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:active {
    background-color: #008DD5 !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    display: flex;
    text-align: center;
}

body .p-tree {
    cursor: pointer;
}

.invite-calender>.datepicker>.p-button:not(a):not(.p-disabled):hover {
    background-color: #c96e28 !important;
    border-color: #2fb0f0 !important;
}

.invite-phone>div>.PhoneInputInput:focus {
    border: 1px solid rgb(127, 127, 232) !important;
}

.invite-phone>div>.PhoneInputInput:active {
    border: 1px solid rgb(127, 127, 232) !important;
}

.invite-phone>div>.PhoneInputInput:target {
    border: 1px solid rgb(127, 127, 232) !important;
}

.invite-phone>div>.PhoneInputInput {
    height: 3rem;
    font-family: 'Roboto';
}
.labels{
    margin-bottom: 0.3rem;
    font-weight: bold !important;
}
.p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    width: 2.35em !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background-color: #d9d9d9;
    color: #000;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background-color: #008DD5;
    color: #ffffff;
    cursor: pointer;
}

.p-treeselect .p-treeselect-label {
    padding: 0rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #ffffff !important;
    margin: 0rem;
}

.p-dialog .p-dialog-header {
    background-color: #d0021b !important;
    color: #ffffff !important;
}

.confirm_close_btn .p-dialog-header {
    height: 4rem;
}

.confirm_close_btn>.p-dialog-footer>.p-confirm-dialog-accept {
    background-color: #d0021b !important;
    border: none !important;
}

.confirm_close_btn>.p-dialog-footer>.p-confirm-dialog-reject>.p-button-label {
    color: #6366F1 !important;
}

.p-treeselect-panel .p-treeselect-header {
    background-color: #ffffff !important;
}

.confirm_close_btn>.p-dialog-footer>.p-confirm-dialog-reject>.p-button-label {
    color: #008DD5 !important;
}

.confirm_invite_detail {
    @include fontStyle("Roboto", 14px, #495057);
}

.detail {
    margin: 0.5rem 0rem 0.5rem 0rem;
}

.phoneNo_invite {
    @include fontStyle("Roboto-Bold", 14px, #D0021B);
}

.planName_invite {
    @include fontStyle("Roboto-bold", 14px, #23547B);
}

.p-treenode-children>li>.p-treenode-content>button {
    display: none !important;
    align-items: center !important;
    margin-left: 1rem !important;
}

.not_saved {
    @include fontStyle("Roboto", 14px, #495057);

}

.not_saved2 {
    @include fontStyle("Roboto-bold", 14px, #d0021b);

}

.date-content {
    margin-bottom: 0px;
    margin-left: -0.5rem;
}

.deduction_title {
    @include fontStyle("Roboto-Bold", 11px, #000000 !important);
}
.main-deduction-div{
    margin: 0rem 0 0.3rem 0 ;
}
.error-deduct{
    margin: 0.3rem 0rem 1rem 0rem;
}
.error-msg{
    margin: 0.3rem 0rem 0.5rem 0.4rem;
}

.p-calendar-disabled input {
    background-color: rgb(225, 223, 223) !important;
}

.p-inputtext:disabled {
    background-color: rgb(225, 223, 223) !important;
    color: #666678 !important;
}

.checkedbox_div_label {
    margin: 0.1rem 0rem 0rem 0.7rem;
}

.unchecked_label {
    @include fontStyle("Roboto-bold", 11px, #666678 !important);
}

#checkbox_title {
    @include fontStyle("Roboto-bold", 11px, #000000);
}

#send_invite_btn {
    @include fontStyle("Roboto-bold", 14px, #ffffff);
    margin: 1rem 0rem 0rem auto;
    width: max-content;
    background-color: #d0021b;
}

#toggled {
    @include fontStyle("RobotoCondensed-bold", 13px, #d0021b);
    margin-left: 0.5rem;
}

#untoggled {
    @include fontStyle("RobotoCondensed-bold", 13px, #495057);
    margin: 0.5rem 0rem 0rem 0.3rem;
}

.main_div_input_switch {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

#close-confirm-dialog {
    margin: 1rem 0rem 0rem auto;
    width: max-content;
    background-color: #d0021b;
}
#amt{
    font-size: 14px;
}
#invite-deduc{
    width: 300px;
}
.calen-div{
    width: 305px !important;
    height: 3rem !important;
    padding-left: 0.5rem;
}
.hideBtn{
    color: #d0021b;
    font-weight: bold;
    margin: 0rem;
}
.acceptBtn{
    color: #fff;
    font-size: 12px;
    background-color: #008DD5;
    border: none;
}
.idoc-div{
    padding: 0.8rem;
    width: 330px;
}
.idoc-pend-txt{
    color: #ffb304;
    font-weight: bolder;
}

.redirect_text{
    cursor: pointer;
    text-decoration: underline;
    color: #008DD5;
}
.payment_text{
    color: #000000;
}
.dialog_text{
    color: #000000;
}
.not_owner_bold_text{
    color: #000000;
    font-weight: bold;
}